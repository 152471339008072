
import { defineComponent } from "vue-demi";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTimesCircle);

export default defineComponent({
  name: "ClearButton"
});

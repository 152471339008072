import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cursor-pointer" }
const _hoisted_2 = { title: "Clear Button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, {
        class: "text-secondary",
        icon: ['fas', 'times-circle']
      })
    ])
  ]))
}
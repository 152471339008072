
import { toast } from "@/common/toast/toast.service";
import ListView from "@/components/common/ListView.vue";
import {
  sentEmailsActions,
  SentEmailGetResult,
  SentEmailsPaginatedQuery,
  SentEmailsService
} from "@/store/sent-emails.module";
import MainNavigation from "@/components/MainNavigation.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faClipboard, faCopy } from "@fortawesome/free-regular-svg-icons";
import { defineComponent, State } from "@vue/runtime-core";
import clipboard from "clipboardy/browser";
import { DateService } from "@/common/date.service";
import { Enum, EnumEntry } from "@/common/contracts/enum-parser";
import { EnumService } from "@/common/enum.service";
import AutoComplete from "primevue/autocomplete";
import { MessageMethodKind, MessageSourceKind } from "@/store/emails.module";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import ClearButton from "@/components/common/ClearButton.vue";
import RenderHtml from "@/components/RenderHtml.vue";

library.add(faClipboard, faCopy, faTimesCircle);

export default defineComponent({
  name: "SentComponent",
  components: {
    MainNavigation,
    ListView,
    AutoComplete,
    ClearButton,
    RenderHtml
  },
  beforeMount() {
    this.methodKinds = Object.freeze(
      Enum.Entries(MessageMethodKind).entries
    ) as EnumEntry<MessageMethodKind>[];
    this.sourceKinds = Object.freeze(
      Enum.Entries(MessageSourceKind).entries
    ) as EnumEntry<MessageSourceKind>[];
  },
  data() {
    return {
      dateService: new DateService(),
      domain: null as string | null | undefined,
      company: null as string | null | undefined,
      method: null as EnumEntry<MessageMethodKind> | null,
      methodKinds: null as any as EnumEntry<MessageMethodKind>[],
      source: null as EnumEntry<MessageSourceKind> | null,
      sourceKinds: null as any as EnumEntry<MessageSourceKind>[],
      from: null as Date | null | undefined,
      to: null as Date | null | undefined,
      search: "",
      dropdownOpen: false,
      sentEmailsActions: sentEmailsActions,
      name: "Sent Emails",
      enumService: new EnumService(),
      showEdit: false
    };
  },
  methods: {
    setShowEdit(showEdit: boolean) {
      this.showEdit = showEdit;
    },
    csv() {
      new SentEmailsService().getPagedCsv();
    },
    searchMethods(search: string) {
      this.methodKinds = Enum.Entries(MessageMethodKind).entries.filter(
        (_) => _.friendlyName.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ) as EnumEntry<MessageMethodKind>[];
    },
    searchSources(search: string) {
      this.sourceKinds = Enum.Entries(MessageSourceKind).entries.filter(
        (_) => _.friendlyName.toLowerCase().indexOf(search.toLowerCase()) !== -1
      ) as EnumEntry<MessageSourceKind>[];
    },
    toggleDropdown() {
      setTimeout(() => (this.dropdownOpen = !this.dropdownOpen), 100);
    },
    filter(query: SentEmailsPaginatedQuery) {
      query.search = this.search;
      query.senders = ["a", "bc"].join(",");
      query.dateFrom = this.from;
      query.dateTo = this.to;
      query.methodKind = this.method?.value;
      query.sourceKind = this.source?.value;
      query.company = this.company;
      query.domain = this.domain;
    },
    addSearchTerm(term: string) {
      this.search ??= "";
      if (this.search.length > 0) {
        this.search += ";";
      }
      this.search += term;
      setTimeout(() => {
        (this.$refs.search as HTMLElement).focus();
      }, 500);
    },
    setClipboard: (value?: string) => {
      if (value) {
        clipboard.write(value);
        toast.info(`Copied '${value}' to clipboard`);
      }
    },
    resolveState: (state: State) => state.sentEmails,
    cast: (items: any) => items as SentEmailGetResult[],
    getAndSetSentItem: async (setSelected: (_: any) => void, id: any, getItem: (id: any) => Promise<any>) => {
      const item = await getItem(id);
      setSelected(item);
    },
  }
});
